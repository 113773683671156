export function findFirstUnitClauseIndex(clauses) {
    return clauses.findIndex((clause) => clause.length === 1);
}
export function propagateUnitClauseByIndex(clauses, unitClauseIndex) {
    if (unitClauseIndex >= clauses.length || unitClauseIndex < 0) {
        return clauses;
    }
    const clauseToRemove = clauses[unitClauseIndex];
    const varToRemove = clauseToRemove[0];
    // Remove clause at index itself
    const stage1 = clauses.filter((_, i) => i !== unitClauseIndex);
    // Remove any other clause that has the unitclause's variable in it with the same polarity
    const stage2 = stage1.filter((disjunctiveClause) => !disjunctiveClause.some((varOccurence) => varOccurence.name === varToRemove.name &&
        varOccurence.polarity === varToRemove.polarity));
    // Remove the  unitclause's variable from any leftover clauses
    const stage3 = stage2.map((disjunctiveClause) => disjunctiveClause.filter((varOccurence) => varOccurence.name !== varToRemove.name));
    return stage3;
}
export function findFirstPureLiteral(clauses) {
    const pureVars = new Set();
    const existingAssignments = new Map();
    for (const clause of clauses) {
        for (const term of clause) {
            pureVars.add(term.name);
        }
    }
    for (const clause of clauses) {
        for (const term of clause) {
            if (existingAssignments.has(term.name) &&
                existingAssignments.get(term.name) !== term.polarity) {
                pureVars.delete(term.name);
            }
            else {
                existingAssignments.set(term.name, term.polarity);
            }
        }
    }
    if (pureVars.size === 0)
        return null;
    else
        return {
            name: [...pureVars][0],
            polarity: existingAssignments.get([...pureVars][0]),
        };
}
export function eliminatePureLiteral(term, literalToEliminate) {
    return propagateVariableAssignment(term, literalToEliminate);
}
export function hasEmptyDisjunctiveClauses(term) {
    return term.some((disClause) => disClause.length === 0);
}
export function extractAllVariableNames(clauses) {
    const variables = new Set();
    for (const clause of clauses) {
        for (const term of clause) {
            variables.add(term.name);
        }
    }
    return variables;
}
function disClauseIsFulfilled(clause, varAss) {
    return clause.some((varOcc) => varOcc.name === varAss.name && varOcc.polarity === varAss.polarity);
}
function removeVarOccFromDisClause(clause, varAss) {
    return clause.filter((varOcc) => varOcc.name !== varAss.name);
}
export function propagateVariableAssignment(term, varAss) {
    const fulfilledClausesRemoved = term.filter((disClause) => !disClauseIsFulfilled(disClause, varAss));
    const variableRemovedFromOtherClauses = fulfilledClausesRemoved.map((disClause) => removeVarOccFromDisClause(disClause, varAss));
    return variableRemovedFromOtherClauses;
}
export function algoStep(term, assignment = new Map()) {
    // Check if term is empty -> SAT
    if (term.length === 0) {
        return [
            {
                stepTaken: "Satisfiable",
                affectedVariable: null,
                immediateAssignment: new Map(),
                resultingTerm: term,
                children: [],
            },
        ];
    }
    // Check for empty disjunctive clauses -> UNSAT
    const isUnsat = hasEmptyDisjunctiveClauses(term);
    if (isUnsat) {
        return [
            {
                stepTaken: "Unsatisfiable",
                affectedVariable: null,
                immediateAssignment: new Map(),
                resultingTerm: term,
                children: [],
            },
        ];
    }
    // Try to find a unit clause
    const unitClauseIndex = findFirstUnitClauseIndex(term);
    if (unitClauseIndex !== -1) {
        const unitVarOccurence = term[unitClauseIndex][0];
        const newTerm = propagateUnitClauseByIndex(term, unitClauseIndex);
        const assignmentToMake = new Map().set(unitVarOccurence.name, unitVarOccurence.polarity);
        return [
            {
                stepTaken: "UnitClausePropagation",
                affectedVariable: unitVarOccurence.name,
                immediateAssignment: assignmentToMake,
                resultingTerm: newTerm,
                children: [],
            },
        ];
    }
    // Try to find a pure Literal
    const pureLiteralVar = findFirstPureLiteral(term);
    if (pureLiteralVar !== null) {
        const assignmentToMake = new Map().set(pureLiteralVar.name, pureLiteralVar.polarity);
        const newTerm = eliminatePureLiteral(term, pureLiteralVar);
        return [
            {
                stepTaken: "PureLiteralElimination",
                affectedVariable: pureLiteralVar.name,
                immediateAssignment: assignmentToMake,
                resultingTerm: eliminatePureLiteral(term, pureLiteralVar),
                children: [],
            },
        ];
    }
    // Else try to split:
    const firstVariableAssName = term[0][0].name;
    return [
        {
            stepTaken: "TryAssignment",
            affectedVariable: firstVariableAssName,
            immediateAssignment: new Map().set(firstVariableAssName, true),
            resultingTerm: propagateVariableAssignment(term, {
                name: firstVariableAssName,
                polarity: true,
            }),
            children: [],
        },
        {
            stepTaken: "TryAssignment",
            affectedVariable: firstVariableAssName,
            immediateAssignment: new Map().set(firstVariableAssName, false),
            resultingTerm: propagateVariableAssignment(term, {
                name: firstVariableAssName,
                polarity: false,
            }),
            children: [],
        },
    ];
}
export function runAlgo(term) {
    const res = algoStep(term);
    // Try to find SAT and return if found
    const someSatRes = res.find((r) => r.stepTaken === "Satisfiable");
    if (someSatRes !== undefined) {
        return [someSatRes];
    }
    // Filter out UNSAT leafes
    const possibleNextSteps = res.filter((r) => r.stepTaken !== "Unsatisfiable");
    for (const r of possibleNextSteps) {
        r.children = runAlgo(r.resultingTerm);
    }
    return res;
}
