let counter = 0;
function getCounter() {
    return counter++;
}
export function getGraphCodeForNodeTree(startNodes, startTerm) {
    const startTermString = conClauseToString(startTerm);
    const startCounter = getCounter();
    return ("graph TD\n" +
        startNodes
            .map((startNode) => dpllAlgoStepNodesToTree(startNode, startTermString, startCounter).join("\n"))
            .join("\n"));
}
export function dpllAlgoStepNodesToTree(startNode, parentTermString, parentCounter) {
    const termString = conClauseToString(startNode.resultingTerm);
    const myCounter = getCounter();
    const arrowFromParent = "N" +
        parentCounter +
        '["' +
        parentTermString +
        '"] -->|"' +
        startNode.stepTaken +
        " " +
        assignmentsToString(startNode.immediateAssignment) +
        ' "| N' +
        myCounter +
        '["' +
        (startNode.stepTaken === "Unsatisfiable"
            ? "❌"
            : startNode.stepTaken === "Satisfiable"
                ? "✅"
                : termString) +
        '"]';
    const childrenArrows = startNode.children
        .map((c) => dpllAlgoStepNodesToTree(c, termString, myCounter))
        .reduce((acc, curr) => [...acc, ...curr], []);
    return [arrowFromParent, ...childrenArrows];
}
export function conClauseToString(term) {
    return ("{" + term.map((disClause) => disClauseToString(disClause)).join("∧") + "}");
}
function disClauseToString(term) {
    return "{" + term.map((varOcc) => varOccToString(varOcc)).join("∨") + "}";
}
function varOccToString(varOcc) {
    return varOcc.polarity ? varOcc.name : "¬" + varOcc.name;
}
function assignmentsToString(assignments) {
    return [...assignments.entries()]
        .map(([key, value]) => key + " ↦ " + value)
        .join(", ");
}
