<script lang="ts">
  import type { ConjunctiveClause } from "./models";
  import { getGraphCodeForNodeTree } from "./dpll-grapher";
  import { parse, SyntaxError } from "./knf-parser";
  import { runAlgo } from "./dpll-algo";
  import mermaid from "mermaid";

  mermaid.mermaidAPI.initialize({
    startOnLoad: false,
  });
  function tryParse(textToParse: string): ConjunctiveClause {
    try {
      return parse(textToParse);
    } catch (ex) {
      console.warn(ex);
      return [];
    }
  }

  let input = "{{X1∨X2∨X3}∧{¬X1∨X2∨¬X4}∧{¬X2}∧{¬X1∨X3}∧{¬X1∨¬X3∨X4}∧{X1∨¬X3}}";
  $: parsed = tryParse(input);
  $: algoResult = parsed.length > 0 ? runAlgo(parsed) : null;
  $: mermaidCode =
    algoResult !== null ? getGraphCodeForNodeTree(algoResult, parsed) : "";
  $: graphSvg =
    mermaidCode !== ""
      ? mermaid.mermaidAPI.render("graphDiv", mermaidCode)
      : "";
</script>

<main>
  <h1>SWK DPLL-Algorithmus</h1>

  <p>
    <label for="knf-klausel">KNF-Klausel hier eingeben:</label>
    <textarea
      id="knf-klausel"
      name="knf-klausel"
      bind:value={input}
      style="width: 100%;"
    />
  </p>

  <p>
    <svg>{@html graphSvg}</svg>
  </p>

  <p>
    <details>
      <summary>Parsed KNF AST</summary>
      <pre>{JSON.stringify(parsed, null, 2)}</pre>
    </details>
    <details>
      <summary>Result JSON</summary>
      <pre>{JSON.stringify(algoResult, null, 2)}</pre>
    </details>
  </p>
</main>

<footer>
  <a href="https://gitlab.fachschaften.org/oh14-dev/swk-dpll">SourceCode</a>
  –
  <a href="https://zohren.xyz/datenschutz.html">Datenschutz</a>
  –
  Powered by
  <a href="https://watercss.kognise.dev/">Water.css</a>, <a href="https://pegjs.org/">PEG.js</a>, <a href="https://svelte.dev/">Svelte</a>, <a href="https://mermaid.js.org/">Mermaid</a>
</footer>

<style>
  svg {
    padding: 0.5%;
    min-height: 50rem;
    min-width: 99%;
    border: 2px solid;
  }
</style>
